import React, { FC, useEffect, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, withRouter } from 'react-router'
import Spinner from 'react-spinkit'

import { getAllCompaniesForFilter } from 'actions/company'
import { exportOrders, getOrders, resetOrders } from 'actions/order'
import { CARD_LIST_PAGE_SIZE } from 'constants/app'
import { BREAKPOINT } from 'constants/design'
import { UserPermission } from 'constants/user'
import { useArchive } from 'effects'
import { localizeDateForBackend } from 'helper/general'
import withErrorBoundary from 'helper/withErrorBoundary'
import { getContainersSelector } from 'selectors/container'
import { createErrorSelector } from 'selectors/error'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import { getOrdersPaginationSelector, getOrdersSelector } from 'selectors/order'

import { ArchiveButton } from '../../common/ArchiveButton'
import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import { OrderFilter } from '../../common/Filter/components/OrderFilter'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { CardList, OrderCard } from '../../common/NewCards'
import PaginatedContent from '../../common/PaginatedContent'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { COMPANY_ROLE } from '../../company/constants'
import { INITIAL_FILTER_OPEN_WIDTH } from '../../inquiry/constants'
import {
  getCoarseFractionItems,
  getFractionsFromItem,
} from '../../inquiry/helpers'
import PageHeader from '../../layout/PageHeader'

export interface OrderFilterValues {
  id?: React.ReactText
  page?: React.ReactText
  page_size?: React.ReactText
  company?: React.ReactText
  created_at__gte?: React.ReactText
  created_at__date__lte?: React.ReactText
  offer?: React.ReactText
  address?: React.ReactText
  status?: React.ReactText
  order_status?: React.ReactText
  coarse_fraction?: React.ReactText
  archive?: boolean
}

/**
 * @description This component displays a page that shows all orders
 */
const OrdersPageComponent: FC = () => {
  const dispatch = useDispatch()
  const ordersPagination = useSelector(getOrdersPaginationSelector)
  const orderList = useSelector(getOrdersSelector)
  const ordersLoading = useSelector(createLoadingSelector(['GET_ORDERS']))
  const exportOrdersLoading = useSelector(
    createLoadingSelector(['EXPORT_ORDERS']),
  )
  const fractionsLoading = useSelector(createLoadingSelector(['GET_FRACTIONS']))

  useEffect(() => {
    dispatch(getAllCompaniesForFilter())
  }, [dispatch])

  const isLoading = useMemo(
    () => ({
      orders: ordersLoading,
      exportOrders: exportOrdersLoading,
      fractions: fractionsLoading,
    }),
    [exportOrdersLoading, fractionsLoading, ordersLoading],
  )
  const errors = useSelector(createErrorSelector(['GET_ORDERS']))

  const containerList = useSelector(getContainersSelector)
  const fractionList = useSelector(getFractionsSelector)

  const location = useLocation<{
    company?: string
    role?: COMPANY_ROLE
    archive?: boolean
  }>()
  const defaultFilters: OrderFilterValues = {
    page_size: CARD_LIST_PAGE_SIZE,
    company: location.state?.company,
    archive: false,
  }
  const [currentFilters, setCurrentFilters] = useState(defaultFilters)
  const [filterOpen, setFilterOpen] = useState(
    window.innerWidth > INITIAL_FILTER_OPEN_WIDTH,
  )
  const [isExportOrderModalOpen, setIsExportOrderModalOpen] = useState(false)
  const [exportStartDate, setExportStartDate] = useState<string>('')
  const [exportEndDate, setExportEndDate] = useState<string>('')

  const getExportFilters = () => {
    let filters = {
      // added for typing purposes
      ...currentFilters,
      direction: undefined,
      price: undefined,
    }

    // When sending these filters the backend is trying to apply them but crashes because there are no fields named
    // like this. Should be fixed with #3385
    delete filters.direction
    delete filters.price

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
      if (filter === 'id' && typeof currentFilters[filter] !== 'number') {
        delete filters.id
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        created_at__gte: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        created_at__date__lte: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.created_at__gte
    delete filters.created_at__date__lte
    delete filters.archive

    return filters
  }

  useArchive({ currentFilters, setCurrentFilters })

  useEffect(() => {
    if (!isLoading.orders && !ordersPagination.loaded && !errors) {
      dispatch(getOrders(undefined, currentFilters))
    }
  }, [currentFilters, dispatch, errors, isLoading, ordersPagination])

  useEffect(() => {
    dispatch(resetOrders())
  }, [dispatch, location.state])

  if (isLoading.fractions) return null

  return (
    <>
      <Helmet>
        <title>
          {I18n.t(
            `pageTitles.orders${location.state?.archive ? 'Archive' : ''}`,
          )}
        </title>
      </Helmet>

      <div className='orders-page'>
        <PageHeader
          title={I18n.t(
            `order.heading${location.state?.archive ? 'Archive' : ''}`,
          )}
          subtitle={I18n.t(
            `order.subtitle${location.state?.archive ? 'Archive' : ''}`,
          )}
        >
          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <ArchiveButton />
            <IconButton
              iconName='experiment'
              iconPosition={ICON_POSITION.RIGHT}
              onClick={() => {
                setFilterOpen(!filterOpen)
              }}
            >
              <Translate
                value={filterOpen ? 'general.hideFilter' : 'general.showFilter'}
              />
            </IconButton>
            <Media
              key='media-extag'
              query={{ minWidth: BREAKPOINT.XLARGE }}
              render={() => (
                <RequiredPermissions
                  requiredPermissions={[UserPermission.EXPORT_ORDERS]}
                >
                  <IconButton
                    iconName='export'
                    iconPosition={ICON_POSITION.RIGHT}
                    onClick={() => setIsExportOrderModalOpen(true)}
                    isDisabled={isLoading.exportOrders}
                    isLoading={isLoading.exportOrders}
                  >
                    <Translate value='general.export' />
                  </IconButton>
                </RequiredPermissions>
              )}
            />
          </ButtonBar>
        </PageHeader>

        <div>
          <Collapse isOpened={filterOpen}>
            <Filter
              containerList={containerList}
              fractionList={getCoarseFractionItems(fractionList)}
              isLoading={isLoading.orders}
              length={orderList.length}
            >
              {/* Ignore TS explicitly and ignore the PyCharm error in your mind: required props passed via <Filter> */}
              {/* @ts-ignore */}
              <OrderFilter
                setCurrentFilterValues={setCurrentFilters}
                currentFilters={currentFilters}
              />
            </Filter>
          </Collapse>

          <PaginatedContent
            page={ordersPagination.current}
            pages={ordersPagination.count}
            onPreviousPageClick={() =>
              dispatch(getOrders(ordersPagination.previous, currentFilters))
            }
            onNextPageClick={() =>
              dispatch(getOrders(ordersPagination.next, currentFilters))
            }
          >
            {/* Loading Indicator */}
            {isLoading.orders && (
              <div className='uk-flex uk-flex-center uk-margin-large-top'>
                <Spinner name='circle' />
              </div>
            )}

            {/* List of orders */}
            {!isLoading.orders && (
              <CardList
                component={OrderCard}
                items={orderList}
                addFunc={item => getFractionsFromItem(item, fractionList)}
                addProps={{ containerList }}
              />
            )}
          </PaginatedContent>
        </div>
      </div>

      <AsyncExportModal
        isOpen={isExportOrderModalOpen}
        onClose={() => setIsExportOrderModalOpen(false)}
        reduxSelector='EXPORT_ORDERS'
        title={I18n.t('order.export.modal.title')}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x => x === null || x === '' || x === 16 || x === undefined,
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportOrders(getExportFilters())}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
        />
      </AsyncExportModal>
    </>
  )
}

export const OrdersPage = withErrorBoundary(withRouter(OrdersPageComponent))
