import { arrayOf, bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import Spinner from 'react-spinkit'
import { compose } from 'recompose'

import withErrorBoundary from 'helper/withErrorBoundary'
import { localizeDateForBackend } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { CARD_LIST_PAGE_SIZE } from 'constants/app'
import { exportMyOffers } from 'actions/offer'
import { CompanySmallScheme } from 'schemes/company'
import { ContainerScheme } from 'schemes/container'
import { FractionScheme } from 'schemes/fraction'
import { OfferSmallScheme } from 'schemes/offer'
import { PaginationScheme } from 'schemes/pagination'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import MyOfferFilter from '../../common/Filter/components/MyOfferFilter'
import {
  BUTTON_BACKGROUND_COLOR,
  ICON_POSITION,
  IconButton,
} from '../../common/IconButton'
import { LinkButton } from '../../common/LinkButton'
import { CardList, OfferCard } from '../../common/NewCards'
import PaginatedContent from '../../common/PaginatedContent'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { INITIAL_FILTER_OPEN_WIDTH } from '../../inquiry/constants'
import {
  getCoarseFractionItems,
  getFractionsFromItem,
} from '../../inquiry/helpers'
import PageHeader from '../../layout/PageHeader'
import { Button } from '../../common/Button'

import connector from './connector'

/**
 * @description This component displays a page that shows all offers
 */
export class MyOffersPageComponent extends Component {
  static propTypes = {
    getMyOffers: func.isRequired,
    getMyOffersInquiredCompanies: func.isRequired,
    exportMyOffers: func.isRequired,
    formReset: func.isRequired,
    containerList: arrayOf(shape(ContainerScheme)).isRequired,
    fractionList: arrayOf(shape(FractionScheme)),
    isLoading: shape({
      myOffers: bool,
      fractionList: bool,
      myOffersInquiredCompaniesList: bool,
      exportMyOffers: bool,
    }),
    offerList: arrayOf(shape(OfferSmallScheme)),
    offersInquiredCompanyList: arrayOf(shape(CompanySmallScheme)),
    offersPagination: shape(PaginationScheme),
  }

  static defaultProps = {
    fractionList: [],
    isLoading: {
      myOffers: false,
      fractionList: false,
      myOffersInquiredCompaniesList: false,
      exportMyOffers: false,
    },
    offerList: [],
    offersInquiredCompanyList: [],
    offersPagination: {},
  }

  state = {
    currentFilterValues: { old_runtime_of_inquiry: false },
    filterOpen: window.innerWidth > INITIAL_FILTER_OPEN_WIDTH,
    isExportModalOpen: false,
    startDate: '',
    endDate: '',
  }

  /**
   * @description Component “lifecycle method” componentDidMount
   */
  componentDidMount() {
    this.props.formReset('company.offersInquiredCompanyList')
    this.props.getMyOffers(
      0,
      this.state.currentFilterValues,
      CARD_LIST_PAGE_SIZE,
    )
  }

  /**
   * @description toggle visibility of filters
   */
  toggleFilter = () => {
    this.setState({ filterOpen: !this.state.filterOpen })
  }

  render() {
    const {
      offersInquiredCompanyList,
      fractionList,
      isLoading,
      offerList,
      getMyOffers,
      offersPagination,
      containerList,
    } = this.props

    const { filterOpen, currentFilterValues, exportStartDate, exportEndDate } =
      this.state

    const getExportFilters = () => {
      let filters = {
        ...currentFilterValues,
      }

      Object.keys(filters).forEach(filter => {
        //remove empty filter
        if (currentFilterValues[filter] === '') {
          delete filters[filter]
          return
        }
        if (
          filter === 'id' &&
          typeof currentFilterValues[filter] !== 'number'
        ) {
          delete filters.id
        }
        if (filter === 'company') {
          filters['customer_company'] = currentFilterValues[filter]
          delete filters.company
        }
      })

      if (exportStartDate) {
        filters = {
          ...filters,
          created_at__gte: localizeDateForBackend(exportStartDate),
        }
      }

      if (exportEndDate) {
        filters = {
          ...filters,
          created_at__date__lte: localizeDateForBackend(exportEndDate),
        }
      }

      return filters
    }

    const getCleanedExportFilters = () => {
      const filters = getExportFilters()
      delete filters.created_at__gte
      delete filters.created_at__date__lte

      return filters
    }

    const setExportStartDate = exportStartDate => {
      this.setState({ exportStartDate: exportStartDate })
    }

    const setExportEndDate = exportEndDate => {
      this.setState({ exportEndDate: exportEndDate })
    }

    if (isLoading.fractionList) return null

    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.offers')}</title>
        </Helmet>

        <div className='my-offers-page'>
          <PageHeader
            title={I18n.t(
              `myOffers.heading${
                this.state.currentFilterValues.old_runtime_of_inquiry
                  ? 'Archive'
                  : ''
              }`,
            )}
            subtitle={I18n.t(
              `myOffers.subtitle${
                this.state.currentFilterValues.old_runtime_of_inquiry
                  ? 'Archive'
                  : ''
              }`,
            )}
          >
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
                onClick={() => {
                  const newFilterValues = {
                    ...currentFilterValues,
                    old_runtime_of_inquiry:
                      !currentFilterValues.old_runtime_of_inquiry,
                  }
                  this.setState({ currentFilterValues: newFilterValues })
                  getMyOffers(0, newFilterValues, CARD_LIST_PAGE_SIZE)
                }}
              >
                <Translate
                  value={`general.button.${
                    this.state.currentFilterValues.old_runtime_of_inquiry
                      ? 'back'
                      : 'archive'
                  }`}
                />
              </Button>
              <IconButton
                iconName='experiment'
                iconPosition={ICON_POSITION.RIGHT}
                onClick={this.toggleFilter}
              >
                <Translate
                  value={
                    filterOpen ? 'general.hideFilter' : 'general.showFilter'
                  }
                />
              </IconButton>

              <Media
                key='media-extag'
                query={{ minWidth: BREAKPOINT.XLARGE }}
                render={() => (
                  <RequiredPermissions
                    requiredPermissions={[UserPermission.EXPORT_OFFERS]}
                  >
                    <IconButton
                      iconName='export'
                      iconPosition={ICON_POSITION.RIGHT}
                      onClick={() => this.setState({ isExportModalOpen: true })}
                      isDisabled={isLoading.exportMyOffers}
                      isLoading={isLoading.exportMyOffers}
                    >
                      <Translate value='general.export' />
                    </IconButton>
                  </RequiredPermissions>
                )}
              />
              <RequiredPermissions
                requiredPermissions={[UserPermission.VIEW_MY_AUTO_OFFER]}
              >
                <LinkButton target='/auto-offer'>
                  <Translate value='general.autoOffer' />
                </LinkButton>
              </RequiredPermissions>
            </ButtonBar>
          </PageHeader>

          <div>
            <Collapse isOpened={filterOpen}>
              <Filter
                companyList={offersInquiredCompanyList}
                fractionList={getCoarseFractionItems(fractionList)}
                isLoading={isLoading.myOffers}
                length={offerList.length}
              >
                <MyOfferFilter
                  getCurrentFilterValues={values => {
                    const newFilterValues = {
                      ...values,
                      old_runtime_of_inquiry:
                        this.state.currentFilterValues.old_runtime_of_inquiry,
                    }
                    this.setState({ currentFilterValues: newFilterValues })
                    getMyOffers(null, newFilterValues, CARD_LIST_PAGE_SIZE)
                  }}
                />
              </Filter>
            </Collapse>

            <PaginatedContent
              page={offersPagination.current}
              pages={offersPagination.count}
              onPreviousPageClick={() =>
                getMyOffers(
                  offersPagination.previous,
                  currentFilterValues,
                  CARD_LIST_PAGE_SIZE,
                )
              }
              onNextPageClick={() =>
                getMyOffers(
                  offersPagination.next,
                  currentFilterValues,
                  CARD_LIST_PAGE_SIZE,
                )
              }
            >
              {/* Loading Indicator */}
              {isLoading.myOffers && (
                <div className='uk-flex uk-flex-center uk-margin-large-top'>
                  <Spinner name='circle' />
                </div>
              )}

              {/* List of orders */}
              {!isLoading.myOffers && (
                <CardList
                  component={OfferCard}
                  items={offerList}
                  className='uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l'
                  addFunc={item => getFractionsFromItem(item, fractionList)}
                  addProps={{ containerList }}
                />
              )}
            </PaginatedContent>
          </div>
        </div>

        <AsyncExportModal
          isOpen={this.state.isExportModalOpen}
          onClose={() => this.setState({ isExportModalOpen: false })}
          reduxSelector='EXPORT_MY_OFFERS'
          title={I18n.t('myOffers.exportModal.title')}
          description_translation_key={
            'asyncExportModalTranslations.descriptionWithFilter'
          }
          notice_translation_key={
            Object.values({
              ...getCleanedExportFilters(),
              old_runtime_of_inquiry: null,
            }).every(x => x === null || x === '')
              ? ''
              : 'asyncExportModalTranslations.dateForm.filterNotice'
          }
          logic={exportMyOffers(getExportFilters())}
          resetOnDispatch={() => {
            setExportStartDate('')
            setExportEndDate('')
          }}
        >
          <DateForm
            setStartDate={setExportStartDate}
            setEndDate={setExportEndDate}
          />
        </AsyncExportModal>
      </>
    )
  }
}

export default compose(withErrorBoundary, connector)(MyOffersPageComponent)
