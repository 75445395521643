import uniqueId from 'lodash.uniqueid'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import Spinner from 'react-spinkit'
import { compose } from 'recompose'

import withErrorBoundary from 'helper/withErrorBoundary'
import { localizeDateForBackend } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { exportRedeemedDiscounts } from 'actions/discount'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import DiscountFilter from '../../common/Filter/components/DiscountFilter'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import PageHeader from '../../layout/PageHeader'
import { DISCOUNT_MODALS } from '../constants'
import DiscountDetails from '../DiscountDetails'
import DiscountList from '../DiscountList'

import connector from './connector'
import DiscountPageProps from './schemes'

/**
 * @description This component shows the discounts.
 * @function
 * @param {Object} props the component props
 */
export class DiscountPage extends Component {
  static propTypes = DiscountPageProps

  static defaultProps = {
    discount: null,
    discountList: [],
    pagination: {},
    isLoading: {
      exportRedeemedDiscounts: false,
      discountList: false,
      discount: false,
    },
    user: null,
  }

  state = {
    openModal: false,
    currentFilterValues: {},
    sortedBy: [],
    isExportModalOpen: false,
    startDate: '',
    endDate: '',
  }

  /**
   * Component “lifecycle method” UNSAFE_componentWillMount
   */
  UNSAFE_componentWillMount() {
    this.props.getDiscounts()
  }

  /**
   * @description call getDiscounts and save sorting values in state
   */
  getSortingValues = state => {
    if (state.sorted.length) {
      this.props.getDiscounts(
        null,
        this.state.currentFilterValues,
        state.sorted,
      )
      this.setState({ sortedBy: state.sorted })
    }
  }

  /**
   * @description Toggle a modal
   * @param modal
   * @param event
   */
  handleModalToggle = (modal = null, event = null) => {
    if (event) event.preventDefault()
    this.setState({
      openModal: modal,
    })
  }

  /**
   * @description Changes the status of the discount. This does a partial update call that changes only the status.
   * @param discount object
   * @param status
   */
  handleChangeStatus = (discount, status) => {
    this.props.updateDiscountStatus({
      id: discount.id,
      status,
    })
  }

  /**
   * @description Open modal to edit a discount
   */
  /* TODO @michal edit logic will be added later */
  handleEditDiscount = () => undefined

  /**
   * @description Open modal to show a discount
   */
  handleShowDiscount = discountId => {
    this.props.getDiscount(discountId)
    this.setState({
      openModal: DISCOUNT_MODALS.DISCOUNT_DETAILS_MODAL,
    })
  }

  /**
   * @description Renders the discount details
   * @return {*}
   */
  renderDiscountDetailsModal() {
    const idDiscountDetailsModalHeadline = uniqueId()
    const { isLoading, discount } = this.props

    return (
      <Modal
        ariaDescribedBy={idDiscountDetailsModalHeadline}
        isOpen={this.state.openModal === DISCOUNT_MODALS.DISCOUNT_DETAILS_MODAL}
        onClose={this.handleModalToggle}
      >
        <ModalHeader
          onClose={this.handleModalToggle}
          title={I18n.t('discountOverview.discountDetails.title')}
          titleId={idDiscountDetailsModalHeadline}
        />
        {/* Loading Indicator */}
        {isLoading.discount && !discount.id && (
          <div className='uk-flex uk-flex-center uk-margin-large-top'>
            <Spinner name='circle' />
          </div>
        )}

        {discount.id && <DiscountDetails discount={discount} />}
      </Modal>
    )
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const { currentFilterValues, exportStartDate, exportEndDate } = this.state

    const getExportFilters = () => {
      let filters = {
        ...currentFilterValues,
      }
      Object.keys(filters).forEach(filter => {
        //remove empty filter
        if (currentFilterValues[filter] === '') {
          delete filters[filter]
          return
        }
      })

      if (exportStartDate) {
        filters = {
          ...filters,
          created_at__gte: localizeDateForBackend(exportStartDate),
        }
      }

      if (exportEndDate) {
        filters = {
          ...filters,
          created_at__date__lte: localizeDateForBackend(exportEndDate),
        }
      }

      return filters
    }

    const setExportStartDate = exportStartDate => {
      this.setState({ exportStartDate: exportStartDate })
    }

    const setExportEndDate = exportEndDate => {
      this.setState({ exportEndDate: exportEndDate })
    }

    const getCleanedExportFilters = () => {
      const filters = getExportFilters()
      delete filters.created_at__gte
      delete filters.created_at__date__lte

      return filters
    }

    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.discounts')}</title>
        </Helmet>

        <div className='discount-page'>
          <PageHeader title={I18n.t('discountOverview.heading')}>
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              <RequiredPermissions
                requiredPermissions={[
                  UserPermission.EXPORT_DISCOUNT_REDEMPTIONS,
                ]}
              >
                <Media
                  key='media-extag'
                  query={{ minWidth: BREAKPOINT.XLARGE }}
                  render={() => (
                    <IconButton
                      iconName='export'
                      iconPosition={ICON_POSITION.RIGHT}
                      onClick={() => this.setState({ isExportModalOpen: true })}
                      isDisabled={this.props.isLoading.exportRedeemedDiscounts}
                      isLoading={this.props.isLoading.exportRedeemedDiscounts}
                    >
                      <Translate value='general.export' />
                    </IconButton>
                  )}
                />
              </RequiredPermissions>
            </ButtonBar>
          </PageHeader>

          <Filter
            isLoading={this.props.isLoading.discountList}
            length={this.props.discountList.length}
          >
            <DiscountFilter
              getCurrentFilterValues={values =>
                this.setState({ currentFilterValues: values })
              }
              sortedBy={this.state.sortedBy}
            />
          </Filter>

          <DiscountList
            pages={this.props.pagination.count}
            page={this.props.pagination.current}
            onPreviousPageClick={() =>
              this.props.getDiscounts(
                this.props.pagination.previous,
                this.state.currentFilterValues,
                this.state.sortedBy,
              )
            }
            onNextPageClick={() =>
              this.props.getDiscounts(
                this.props.pagination.next,
                this.state.currentFilterValues,
                this.state.sortedBy,
              )
            }
            discount={this.props.discount}
            discountList={this.props.discountList}
            onFetchData={this.getSortingValues}
            isLoading={this.props.isLoading.discountList}
            onStatusChange={this.handleChangeStatus}
            onModalToggle={this.handleModalToggle}
            onEditDiscount={this.handleEditDiscount}
            onShowDiscount={this.handleShowDiscount}
            user={this.props.user}
          />

          {/* Modals */}
          {this.renderDiscountDetailsModal()}
        </div>

        <AsyncExportModal
          isOpen={this.state.isExportModalOpen}
          onClose={() => this.setState({ isExportModalOpen: false })}
          reduxSelector='EXPORT_REDEEMED_DISCOUNTS'
          title={I18n.t('discountOverview.exportModalTitle')}
          logic={exportRedeemedDiscounts(getExportFilters())}
          description_translation_key={
            'asyncExportModalTranslations.descriptionWithFilter'
          }
          notice_translation_key={
            Object.values(getCleanedExportFilters()).every(
              x => x === null || x === '' || x === undefined,
            )
              ? ''
              : 'asyncExportModalTranslations.dateForm.filterNotice'
          }
          resetOnDispatch={() => {
            setExportStartDate('')
            setExportEndDate('')
          }}
        >
          <DateForm
            setStartDate={setExportStartDate}
            setEndDate={setExportEndDate}
          />
        </AsyncExportModal>
      </>
    )
  }
}

export default compose(withErrorBoundary, connector)(DiscountPage)
