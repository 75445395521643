import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { localizeDateForBackend } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { triggerMynatureExports } from 'actions/dsz'
import {
  exportMaklerPremiumCompanies,
  getMaklerPremiumCompanies,
} from 'actions/company'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'
import { APP_CONSTANTS } from 'constants/app'

import { AsyncDSZExportModal } from '../../common/AsyncDSZExportModal/AsyncDSZExportModal'
import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import { FilterReset } from '../../common/Filter/components/FilterReset'
import { cleanUpAndCountFiltersForReset } from '../../common/Filter/components/FilterReset/helpers'
import { ICON_NAME } from '../../common/Fontello'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { InputText } from '../../common/InputText'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { COMPANY_ROLE } from '../../company/constants'
import { INITIAL_FILTER_OPEN_WIDTH } from '../../inquiry/constants'
import PageHeader from '../../layout/PageHeader'
import { AddWasteDisposerWrapper } from '../AddWasteDisposerWrapper'
import { AddWasteProducerWrapper } from '../AddWasteProducerWrapper'
import { getBusinessSegments } from '../helpers'
import { Select } from '../../Select/Select'

import { WasteDisposersList } from './WasteDisposersList'
import { WasteProducersList } from './WasteProducersList'

const CUSTOMER = 'customer'
const PARTNER = 'partner'

export interface DisposerProducerFilters {
  company?: number
  order_by?: string
  makler_premium_company?: React.ReactText
  role?: COMPANY_ROLE
  registration_number?: string
}

export type DisposerProducerListContext = {
  currentFilters?: DisposerProducerFilters
  defaultSorted: { id: string; desc: boolean }[]
  setCurrentFilters: (DisposerProducerFilters) => void
}
// eslint-disable-next-line no-redeclare,@typescript-eslint/no-redeclare
export const DisposerProducerListContext =
  createContext<DisposerProducerListContext>({
    currentFilters: undefined,
    defaultSorted: [{ id: 'id', desc: false }],
    setCurrentFilters: () => undefined,
  })

/**
 * @description This component displays a list of the WasteProducers or WasteDisposers for the
 * current user's company, if the company is a Makler-Premium-Company.
 * @function
 */
export const DisposerProducerPage = () => {
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUserSelector)

  // define business_segment booleans
  const { isDSZ } = getBusinessSegments(user)

  const urlPath = useLocation().pathname.split('/')
  const segment = urlPath.pop() as string

  const isLoadingMaklerPremium = useSelector(
    createLoadingSelector(['EXPORT_MAKLER_PREMIUM_COMPANIES']),
  )
  const isLoadingMyNature = useSelector(
    createLoadingSelector(['TRIGGER_MY_NATURE_EXPORTS']),
  )

  // Producer Modal
  const [isAddWasteProducerModalOpen, setIsAddWasteProducerModalOpen] =
    useState(false)
  const openAddWasteProducerFormModal = () => {
    setIsAddWasteProducerModalOpen(true)
  }

  const idWasteProducerFormModalHeadline = uniqueId()

  const closeProducerModal = () => {
    setIsAddWasteProducerModalOpen(false)
  }
  // End Producer Modal

  // Disposer Modal
  const [isAddWasteDisposerModalOpen, setIsAddWasteDisposerModalOpen] =
    useState(false)
  const openAddWasteDisposerFormModal = () => {
    setIsAddWasteDisposerModalOpen(true)
  }

  const idWasteDisposerFormModalHeadline = uniqueId()

  const closeDisposerModal = () => {
    setIsAddWasteDisposerModalOpen(false)
  }
  // End Disposer Modal

  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [isDSZMynatureExportModalOpen, setIsDSZMynatureExportModalOpen] =
    useState(false)

  const [filterOpen, setFilterOpen] = useState(
    window.innerWidth >= INITIAL_FILTER_OPEN_WIDTH,
  )
  const defaultSorted = [{ id: 'id', desc: true }]
  const SEGMENT_MAP = useMemo(
    () => ({
      [CUSTOMER]: COMPANY_ROLE.WASTE_PRODUCER,
      [PARTNER]: COMPANY_ROLE.WASTE_COMPANY,
    }),
    [],
  )
  const exportTranslations = {
    [CUSTOMER]:
      'disposerProducerPageTranslations.wasteProducersPageTranslations.exportModalTitle',
    [PARTNER]:
      'disposerProducerPageTranslations.wasteDisposersPageTranslations.exportModalTitle',
  }

  const defaultFilters: DisposerProducerFilters = {
    order_by: JSON.stringify(defaultSorted),
    makler_premium_company: user.company,
    role: SEGMENT_MAP[segment],
    registration_number: '',
  }
  const [currentFilters, setCurrentFilters] = useState<any>(defaultFilters)

  const [exportStartDate, setExportStartDate] = useState<string>('')
  const [exportEndDate, setExportEndDate] = useState<string>('')
  const [registrationNumber, setRegistrationNumber] = useState<string>('')

  const getExportFilters = () => {
    let filters = {
      ...currentFilters,
      role: SEGMENT_MAP[segment],
    }
    delete filters.page
    delete filters.order_by

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        created_at__gte: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        created_at__date__lte: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.created_at__gte
    delete filters.created_at__date__lte
    delete filters.makler_premium_company
    delete filters.role

    return filters
  }

  useEffect(() => {
    if (currentFilters.role !== SEGMENT_MAP[segment]) {
      setCurrentFilters({
        ...currentFilters,
        role: SEGMENT_MAP[segment],
      })
    }
  }, [SEGMENT_MAP, currentFilters, segment])

  useEffect(() => {
    dispatch(getMaklerPremiumCompanies(null, defaultFilters))
    // only change with segment, because defaultFilters seems to switch randomly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, segment])

  const handleSubmit = () => {
    const filters = {
      ...currentFilters,
      registration_number: registrationNumber,
    }
    registrationNumber !== ''
      ? dispatch(getMaklerPremiumCompanies(null, filters))
      : dispatch(getMaklerPremiumCompanies(null, defaultFilters))
  }

  return (
    <>
      {segment === CUSTOMER && (
        <>
          {/*PAGE TITLE*/}
          <Helmet>
            <title>{I18n.t('pageTitles.maklerpremiumWasteProducer')}</title>
          </Helmet>

          {/*BUTTONS*/}
          <PageHeader>
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              {/*COMPANY EXPORT*/}
              <Media
                key='media-extag'
                query={{ minWidth: BREAKPOINT.XLARGE }}
                render={() => (
                  <RequiredPermissions
                    requiredPermissions={[UserPermission.EXPORT_CUSTOMER_DATA]}
                  >
                    <IconButton
                      iconName='export'
                      iconPosition={ICON_POSITION.RIGHT}
                      onClick={() => setIsExportModalOpen(true)}
                      isDisabled={isLoadingMaklerPremium}
                      isLoading={isLoadingMaklerPremium}
                    >
                      <Translate value='general.export' />
                    </IconButton>
                  </RequiredPermissions>
                )}
              />

              {/*DSZ MYNATURE EXPORTS*/}
              {isDSZ && (
                <Media
                  key='media-extag'
                  query={{ minWidth: BREAKPOINT.XLARGE }}
                  render={() => (
                    <RequiredPermissions
                      requiredPermissions={[
                        UserPermission.EXPORT_DSZ_MYNATURE_COMPANIES,
                      ]}
                    >
                      <IconButton
                        iconName='export'
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => setIsDSZMynatureExportModalOpen(true)}
                        isDisabled={isLoadingMyNature}
                        isLoading={isLoadingMyNature}
                      >
                        <Translate value='disposerProducerPageTranslations.wasteProducersPageTranslations.dszMynatureExport.buttonLabel' />
                      </IconButton>
                    </RequiredPermissions>
                  )}
                />
              )}

              {/*ADD CUSTOMER*/}
              <IconButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                iconName='plus'
                iconPosition={ICON_POSITION.RIGHT}
                onClick={openAddWasteProducerFormModal}
              >
                <Translate value='disposerProducerPageTranslations.wasteProducersPageTranslations.customer' />
              </IconButton>

              {/*HIDE FILTERS*/}
              <IconButton
                iconName={ICON_NAME.EXPERIMENT}
                iconPosition={ICON_POSITION.RIGHT}
                onClick={() => {
                  setFilterOpen(!filterOpen)
                }}
              >
                <Translate
                  value={
                    filterOpen ? 'general.hideFilter' : 'general.showFilter'
                  }
                />
              </IconButton>
            </ButtonBar>
          </PageHeader>

          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <FilterReset
              onResetFilter={() => {
                setCurrentFilters({})
                handleSubmit()
              }}
              showResetFilterButton={
                cleanUpAndCountFiltersForReset(currentFilters) > 0
              }
            />
          </ButtonBar>
          {/*FILTERS*/}
          <Collapse isOpened={filterOpen}>
            {isDSZ ? (
              <div className='disposer-producer-page-filter'>
                <Filter>
                  {/*prettier-ignore*/}
                  <Select
                    urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
                    urlPart2='&onlyActive=false&maklerpremium_and_role=1'
                    getValue={(e) => e.id}
                    getLabel={(e) => e.id === 0 ? e.name : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location} / ${e?.central_contact} / ${e?.central_contact_email}`}
                    label={I18n.t('disposerProducerPageTranslations.companyFilter.label')}
                    setValue={e => {
                      setCurrentFilters({...currentFilters, id: e?.value ? e.value : ''})
                      if (e?.value) {dispatch(getMaklerPremiumCompanies(null, {...currentFilters, id: e.value}))}
                      else {dispatch(getMaklerPremiumCompanies(null, defaultFilters))}
                    }}
                    selectedValue={currentFilters?.id}
                    selecting={'company'}
                    withCheckmark
                  />
                </Filter>
                <InputText
                  label={I18n.t(
                    'disposerProducerPageTranslations.lucidRegistrationNumber.label',
                  )}
                  placeholder={I18n.t(
                    'disposerProducerPageTranslations.lucidRegistrationNumber.placeholder',
                  )}
                  maxLength={16}
                  name='registration_number'
                  onChange={e => setRegistrationNumber(e.target.value)}
                  onSubmit={handleSubmit}
                  value={registrationNumber}
                />
              </div>
            ) : (
              <Filter>
                {/*prettier-ignore*/}
                <Select
                  urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
                  urlPart2='&onlyActive=false&maklerpremium_and_role=1'
                  getValue={(e) => e.id}
                  getLabel={(e) => e.id === 0 ? e.name : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location} / ${e?.central_contact} / ${e?.central_contact_email}`}
                  label={I18n.t('disposerProducerPageTranslations.companyFilter.label')}
                  setValue={e => {
                    setCurrentFilters({...currentFilters, id: e?.value ? e.value : ''})
                    if (e?.value) {dispatch(getMaklerPremiumCompanies(null, {...currentFilters, id: e.value}))}
                    else {dispatch(getMaklerPremiumCompanies(null, defaultFilters))}
                  }}
                  selectedValue={currentFilters?.id}
                  selecting={'company'}
                  withCheckmark
                />
              </Filter>
            )}
          </Collapse>

          {/*PAGE CONTENT*/}
          <DisposerProducerListContext.Provider
            value={{
              currentFilters,
              defaultSorted,
              setCurrentFilters,
            }}
          >
            <WasteProducersList />
          </DisposerProducerListContext.Provider>

          <Modal
            ariaDescribedBy={idWasteProducerFormModalHeadline}
            isOpen={isAddWasteProducerModalOpen}
            onClose={closeProducerModal}
          >
            <ModalHeader
              onClose={closeProducerModal}
              title={I18n.t(
                'maklerpremium.addWasteProducerModal.form.header.title',
              )}
              titleId={idWasteProducerFormModalHeadline}
            />
            <AddWasteProducerWrapper onSuccess={closeProducerModal} />
          </Modal>
        </>
      )}

      {segment === PARTNER && (
        <>
          <Helmet>
            <title>{I18n.t('pageTitles.maklerpremiumWasteDisposer')}</title>
          </Helmet>
          <PageHeader>
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              <Media
                key='media-extag'
                query={{ minWidth: BREAKPOINT.XLARGE }}
                render={() => (
                  <RequiredPermissions
                    requiredPermissions={[UserPermission.EXPORT_COMPANIES]}
                  >
                    <IconButton
                      iconName='export'
                      iconPosition={ICON_POSITION.RIGHT}
                      onClick={() => setIsExportModalOpen(true)}
                      isDisabled={isLoadingMaklerPremium}
                      isLoading={isLoadingMaklerPremium}
                    >
                      <Translate value='general.export' />
                    </IconButton>
                  </RequiredPermissions>
                )}
              />
              <IconButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                iconName='plus'
                iconPosition={ICON_POSITION.RIGHT}
                onClick={openAddWasteDisposerFormModal}
              >
                <Translate value='disposerProducerPageTranslations.wasteDisposersPageTranslations.partner' />
              </IconButton>
              <IconButton
                iconName={ICON_NAME.EXPERIMENT}
                iconPosition={ICON_POSITION.RIGHT}
                onClick={() => {
                  setFilterOpen(!filterOpen)
                }}
              >
                <Translate
                  value={
                    filterOpen ? 'general.hideFilter' : 'general.showFilter'
                  }
                />
              </IconButton>
            </ButtonBar>
          </PageHeader>

          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <FilterReset
              onResetFilter={() => {
                setCurrentFilters({})
                handleSubmit()
              }}
              showResetFilterButton={
                cleanUpAndCountFiltersForReset(currentFilters) > 0
              }
            />
          </ButtonBar>
          {/*FILTERS*/}
          <Collapse isOpened={filterOpen}>
            <Filter>
              <Select
                urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
                urlPart2='&onlyActive=false&maklerpremium_and_role=2'
                getValue={e => e.id}
                getLabel={e =>
                  e.id === 0
                    ? e.name
                    : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location} / ${e?.central_contact} / ${e?.central_contact_email}`
                }
                label={I18n.t(
                  'disposerProducerPageTranslations.companyFilter.label',
                )}
                setValue={e => {
                  setCurrentFilters({
                    ...currentFilters,
                    id: e?.value ? e.value : '',
                  })
                  if (e?.value) {
                    dispatch(
                      getMaklerPremiumCompanies(null, {
                        ...currentFilters,
                        id: e.value,
                      }),
                    )
                  } else {
                    dispatch(getMaklerPremiumCompanies(null, defaultFilters))
                  }
                }}
                selectedValue={currentFilters?.id}
                selecting={'company'}
                withCheckmark
              />
            </Filter>
          </Collapse>

          <DisposerProducerListContext.Provider
            value={{
              currentFilters,
              defaultSorted,
              setCurrentFilters,
            }}
          >
            <WasteDisposersList />
          </DisposerProducerListContext.Provider>

          <Modal
            ariaDescribedBy={idWasteDisposerFormModalHeadline}
            isOpen={isAddWasteDisposerModalOpen}
            onClose={closeDisposerModal}
          >
            <ModalHeader
              onClose={closeDisposerModal}
              title={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.header.title',
              )}
              titleId={idWasteDisposerFormModalHeadline}
            />
            <AddWasteDisposerWrapper onSuccess={closeDisposerModal} />
          </Modal>
        </>
      )}

      <AsyncExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        reduxSelector='EXPORT_MAKLER_PREMIUM_COMPANIES'
        title={I18n.t(exportTranslations[segment])}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x => x === null || x === '',
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportMaklerPremiumCompanies(getExportFilters())}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
        />
      </AsyncExportModal>

      <AsyncDSZExportModal
        isOpen={isDSZMynatureExportModalOpen}
        onClose={() => setIsDSZMynatureExportModalOpen(false)}
        reduxSelector='TRIGGER_MY_NATURE_EXPORTS'
        title={I18n.t(
          'disposerProducerPageTranslations.wasteProducersPageTranslations.dszMynatureExport.exportModalTitle',
        )}
        logic={triggerMynatureExports}
      />
    </>
  )
}
